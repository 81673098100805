.wrapper {
  z-index: 1000;
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #3e5068;
  background-color: #2C405A !important;
}

.show {
  width: 285px;
}

.hidden {
  width: 70px;
}

.barContainer{
  height: calc(100vh - 120px);
}
