@import 't4b-core-frontend/src/css/colors';

.header {
  background-color: #f2f1f1 !important;
  padding-bottom: 0;
  padding-right: 8px;
}

.description,
.descriptionAppName {
  display: flex;
  align-items: flex-start;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-bottom: 9px;
}

.description {
  margin-bottom: 15px;
}

.descriptionHovered,
.descriptionNotHovered {
  width: 65%;
  white-space: nowrap;
  padding-bottom: 6px;
}

.descriptionNotHovered {
  text-overflow: ellipsis;
  overflow: hidden;
}

.control {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
}

.active,
.inactive {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 5px;
}

.active {
  background-color: #65baa5;
}

.inactive {
  background-color: #f48a8a;
}
