@import 't4b-core-frontend/src/css/colors';

.button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  background: transparent !important;
  width: 15px !important;
  height: 10px !important;
  outline: none !important;

  &:hover {
    background: none;
    color: rgb(221, 215, 215);
  }
  &:active {
    color: white;
  }
  &::after {
    display: none !important;
  }
  &::before {
    display: none !important;
  }
}

.menu {
  position: fixed !important;
  z-index: 100000 !important;
}
