@import 't4b-core-frontend/src/css/colors';

.dropdownContainerPopover {
  border-radius: 8px;
}

.dropdownContainer {
  padding-bottom: 33px;
}

.dropdownContainer,
.dropdownContainerPopover {
  display: flex;
  width: 100%;

  .dropdownBorder {
    min-width: 4px;
    max-width: 4px;
    background: white;
    margin: 10px 34px;
    border-radius: 5px;
  }

  .dropdownProduct {
    position: relative;
    width: 220px;
    min-height: 35px;
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  .dropdownProductLabel {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 15px;
    width: 100%;
  }

  .editIndicator,
  .editIndicatorPopover {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    color: white;
  }

  .editIndicator {
    left: -22px;
  }

  .editIndicatorPopover {
    left: -17px;
  }

  .workStatusIndicator {
    min-width: 6px !important;
    min-height: 6px !important;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 8px;
  }

  .productInactive {
    color: $app-color-text-gray;
  }
}

.dropdownGroupTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 135%;
  color: #ffffff;
  background: none;
  border: none;
  padding-left: 0;
  word-break: break-word;
  text-align: left;
}
