.popoverContainer {
  width: 210px;
}

.popoverBody {
  padding: 10px;
}

.toggleButton {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(28, 52, 81, 0.7);
  padding: 0;
  margin: 0;
  margin-top: 12px;
  margin-right: 1px;
  height: 10px;
  &:hover {
    opacity: 0.7;
  }
}

.itemButton {
  background: none;
  margin: 2px 0;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: #212529;
  text-align: left;

  &:hover {
    background: rgba(28, 52, 81, 0.07);
    border-radius: 2px;
  }
}
