.leftContainer {
  width: 25%;
}

.rightContainer {
  width: 75%;
}

.notification {
  font-weight: 400;
  font-size: 14px;
  line-height: 127%;
  color: #212529;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
  background: rgba(75, 102, 135, 0.1);
  border-radius: 4px;
  margin-bottom: 15px;
}
