@import 't4b-core-frontend/src/css/colors';

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;

  .productEditIndicator {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 12px;
    height: 12px;
    color: white;
  }
}

.linkIconWrapper {
  height: 65px;
  display: flex;
  align-items: center;
  color: white;
  margin-left: 5px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
}

.sidebarPopover {
  position: absolute;
  width: 230px;
  top: 10px;
  left: 80px;
  // z-index: 100000 !important;
  background: #397b8e;
  border-radius: 8px;
  &::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 11px;
    border: 10px solid transparent;
    border-right: 10px solid #397b8e;
  }
}

.linkWrapper {
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  transition: 0.3s;

  &:hover {
    color: white;
  }
}

.label {
  margin-left: 5px !important;
  font-size: 16px;
}

.dropdownButton {
  margin-left: 16px;
  padding: 0 !important;
  overflow: hidden;
  width: 20px;
  height: 17px;
  padding: 5px;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  &:hover {
    color: rgb(228, 226, 226);
  }
  &:active {
    color: rgb(255, 255, 255);
    border: none;
  }
  &:focus {
    border: none;
    outline: none;
  }
}

.dropdownContainer {
  display: flex;
  width: 100%;
  padding-bottom: 20px;

  .dropdownBorder {
    min-width: 1px;
    max-width: 1px;
    background: white;
    opacity: 0.4;
    margin: 10px 34px;
  }

  .dropdownProduct {
    position: relative;
    width: 100%;
    min-height: 35px;
    display: flex;
    align-items: center;
    font-weight: 400;
    &:hover {
      font-weight: bold;
    }
  }

  .dropdownProductLabel {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 15px;
    width: 100%;
  }

  .editIndicator {
    position: absolute;
    bottom: 12px;
    right: -15px;
    width: 10px;
    height: 10px;
    color: white;
  }

  .workStatusIndicator {
    min-width: 6px !important;
    min-height: 6px !important;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 10px;
  }

  .productInactive {
    color: $app-color-text-gray;
  }
}
