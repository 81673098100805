.card {
  border-top-left-radius: 0 !important;
  border-top: none;
}

.header {
  width: fit-content;
  background: #f8f4f4;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-left: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
