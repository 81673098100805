@import 't4b-core-frontend/src/css/colors';

.header {
    min-height: 37px;
}

.title {
    font-size: 17px;
    font-weight: 450;
    padding-left: 10px;
    color: white;
    white-space: nowrap;
    margin: 0;
    &:first-letter {
        text-transform: capitalize;
    }
}

.button {
    background: none;
    border: none;
    margin-right: 5px;
    color: white;
}
