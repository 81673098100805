.groupCard {
  border: 1px solid #4b6687;
  margin-bottom: 32px;

  .header,
  .headerCollapsed {
    cursor: pointer;
    padding: 18px 32px;
    display: flex;
    align-items: center;
    color: #2c405a;
    transition: .5s;
    border-bottom: 1px solid rgba($color: #4b6687, $alpha: 0);
  }

  .header {
    border-bottom: 1px solid #4b6687;
    transition: .5s;
  }

  .title {
    margin: 0;
    color: #2c405a;
    font-size: 22px;
    line-height: 135%;
    word-break: break-word;
  }

  .body {
    padding: 18px 22px;
    display: flex;
    flex-wrap: wrap;
  }
}

.groupOthers {
  margin-bottom: 30px;

  .header {
    padding-left: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    color: #2c405a;
  }

  .container {
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .title {
    color: #2c405a;
    font-size: 22px;
    line-height: 135%;
  }
}
