// old bootstrap
.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: ($spacer * 0.25) !important;
}

.ml-2 {
  margin-left: ($spacer * 0.5) !important;
}

.ml-3 {
  margin-left: $spacer !important;
}

.ml-4 {
  margin-left: ($spacer * 1.5) !important;
}

.ml-5 {
  margin-left: ($spacer * 3) !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: ($spacer * 0.25) !important;
}

.mr-2 {
  margin-right: ($spacer * 0.5) !important;
}

.mr-3 {
  margin-right: $spacer !important;
}

.mr-4 {
  margin-right: ($spacer * 1.5) !important;
}

.mr-5 {
  margin-right: ($spacer * 3) !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: ($spacer * 0.25) !important;
}

.pl-2 {
  padding-left: ($spacer * 0.5) !important;
}

.pl-3 {
  padding-left: $spacer !important;
}

.pl-4 {
  padding-left: ($spacer * 1.5) !important;
}

.pl-5 {
  padding-left: ($spacer * 3) !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: ($spacer * 0.25) !important;
}

.pr-2 {
  padding-right: ($spacer * 0.5) !important;
}

.pr-3 {
  padding-right: $spacer !important;
}

.pr-4 {
  padding-right: ($spacer * 1.5) !important;
}

.pr-5 {
  padding-right: ($spacer * 3) !important;
}

