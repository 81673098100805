.button {
  color: white;
  border: none;
  background: transparent;
  margin-right: 10px;
  transition: .3s;
  &:hover {
    color: rgb(200, 199, 199);
  }
  &:active {
    color: white;
  }
  &:focus {
    outline: none;
  }
}
