.title {
    line-height: 32px;
    font-size: 24px;
    min-width: 50%;
    word-wrap: break-word;
    word-break: break-all;
}

.button {
    padding: 10px;
    min-width: 90px;
}
