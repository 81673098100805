.app-bg-primary {
  background-color: #e5eeff;
}

.bg-white {
  background-color: white !important;
}

.app-bg-gray {
  background: rgba(75, 102, 135, 0.1);
}

.bg-color-mt-connection {
  background: #2b778d !important;
}

.bg-color-db-connection {
  background: #4b8581 !important;
}

.border-left-mt-connection {
  border-left: 3px solid #2b778d !important;
}

.bg-color-mail-sender {
  background: #344e6f !important;
}

.app-bg-green {
  background: #65baa5;
}

.app-bg-dark-green {
  background: #1c9477;
}

.app-bg-navi-blue {
  background: #2c405a;
}

.app-bg-middle-blue {
  background: #397b8e;
}

.block-bg-header-default {
  background: #4b6687;
}

.block-header-1 {
  background: #2c405a;
}

.block-header-2 {
  background: #397b8e;
}

.block-header-3 {
  background: #4b6687;
}

.block-header-4 {
  background: #5ca6bc;
}

.app-color-default {
  color: #4b6687;
}

.bg-blur {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
}

.app-bg-transparent {
  background: transparent !important;
}

